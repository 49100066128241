import React from "react";
import { AppContext } from '../AppContextProvider/AppContextProvider';
import ContactInfo from '../ContactInfo/ContactInfo'
import './Contact.css';

export class Contact extends React.Component {

    render() {
        return (

            <section className="section-contact" id="contact">
                <div className="section-content-wrapper">
                    <div className="section-content">
                        <h3 className="section-nav flippedY">{this.context.state.content.contact.nav}</h3>
                        <form id="contactForm">
                            <input id="firstName" type="text" placeholder={this.context.state.content.contact.firstName}></input>
                            <input id="lastName 7ku5    " type="text" placeholder={this.context.state.content.contact.lastName}></input>
                            <input id="email" type="text" placeholder={this.context.state.content.contact.email}></input>
                            <textarea rows="5" cols="60" id="message" placeholder={this.context.state.content.contact.message}></textarea>
                            <input id="send" type="submit" value={this.context.state.content.contact.sendButton}></input>
                        </form>
                        <ContactInfo />
                    </div>
                </div>
            </section>
        );

    }
}

export default Contact
Contact.contextType = AppContext;
