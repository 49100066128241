import React, { Component } from "react";
import { AppContext } from '../AppContextProvider/AppContextProvider'
import './Footer.css';


export default class Footer extends Component {

    render() {
        return (
            <section className="section-footer" id="contact">
                <div className="section-content-wrapper">
                    <div className="section-content">
                        <div className="artifact"></div>
                        <div id="info">
                            <div className="infoGroup" id="emailGroup">
                                <label className="footerLabel">{this.context.state.content.contact.ourEmailLabel}</label>
                                <label>{this.context.state.content.contact.ourEmail}</label>
                            </div>
                            <div className="infoGroup" id="phoneGroup">
                                <label className="footerLabel">{this.context.state.content.contact.ourPhoneLabel}</label>
                                <label>{this.context.state.content.contact.ourPhone}</label>
                            </div>
                            <div className="infoGroup" id="addressGroup">
                                <label className="footerLabel">{this.context.state.content.contact.ourAddressLabel}</label>
                                <label>{this.context.state.content.contact.ourAddress}</label>
                            </div>
                            <div className="infoGroup" id="openGroup">
                                <label className="footerLabel">{this.context.state.content.contact.ourOpenHoursLabel}</label>
                                <label>{this.context.state.content.contact.ourOpenHours}</label>
                            </div>
                        </div>
                        {/* <button id="languageButton">ENGLISH</button> */}
                    </div>
                </div>
            </section>

        );
    }
}
Footer.contextType = AppContext;
