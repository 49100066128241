import React from "react";


import Expander from "../Expander/Expander";
import './Place.css';
import { AppContext } from '../AppContextProvider/AppContextProvider'
export class Place extends React.Component {
  constructor(props) {
    super();

    this.state = {
    }
  }


  render() {

    return (

      <section className="section-place" id="place">
        <div className="section-content-wrapper">

          <div className="section-content">
            <div className="artifact"></div>
            <div className="artifact2"></div>
            <h3 className="section-nav flippedY">{this.context.state.content.place.nav}</h3>
            <h2 className="heading">{this.context.state.content.place.heading}</h2>
            <article className="text-group">
              <p className="copy-text" >{this.context.state.content.place.paragaph1}</p>
              <Expander enabled={window.innerHeight < 700} className="copy-text" title={this.context.state.content.misc.readMore}>{this.context.state.content.place.paragaph2}</Expander>
            </article>
            <div className="hero-image"></div>

          </div>
        </div>
      </section>
    );

  }
}

export default Place
Place.contextType = AppContext;
