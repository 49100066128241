import React, { Component } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import AppContextProvider from './Components/AppContextProvider/AppContextProvider'
import Navbar from './Components/Navbar/Navbar';
import QuickMenu from './Components/QuickMenu/QuickMenu'
import Home from './Components/Home/Home'
import Story from './Components/Story/Story';
import Place from './Components/Place/Place';
import Wines from './Components/Wines/Wines';
import Approach from './Components/Approach/Approach';
import ContactAlt from './Components/ContactAlt/ContactAlt'
import Acquire from './Components/Acquire/Acquire';
import Contact from './Components/Contact/Contact';
import Footer from './Components/Footer/Footer';

import './App.css';

function HomeSection() {
  return (
    <React.Fragment>
      <Home />
      <Story />
      <Approach />
      <Place />
    </React.Fragment>
  );
}

function WinesSection() {
  return <Wines />;
}

function ShopSection() {
  return (
    <React.Fragment>
      {/* <Acquire /> */}
      {/* <Contact /> */}
      {/* <Footer /> */}
      {<ContactAlt/>}
    </React.Fragment>
  );
}



class App extends Component {
  render() {
    return (
      <Router>
        <div className="App">
          <AppContextProvider>
            <Navbar />
            <QuickMenu />
            <Switch>
              <Route path="/" exact component={HomeSection} />
              <Route path="/wines" component={WinesSection} />
              <Route path="/shop" component={ShopSection} />
            </Switch>
          </AppContextProvider>
        </div>
      </Router>
    );
  }
}

export default App;
