import React, { Component } from "react";
import { AppContext } from '../AppContextProvider/AppContextProvider'

import styles from './Home.module.css';

export default class Home extends Component {


  render() {

    return (
      <section className={styles.sectionHome} id="home">
        <div className={styles.contentWrapper}>
          <div className={styles.hero}>
            <h1 className={styles.copyHeading}>{this.context.state.content.homepage.heading}</h1>
            <p className={styles.copyText}>{this.context.state.content.homepage.subheading}</p>
          </div>
        </div>
      </section >
    );
  }
}

Home.contextType = AppContext;