import React from "react";
import { AppContext } from '../AppContextProvider/AppContextProvider';
import styles from './ContactAlt.module.css';

export class ContactAlt extends React.Component {

    render() {
        return (
            <section className={styles.sectionContactAlt} id="contact-alt">
                <div className={styles.contentWrapper}>
                        <React.Fragment>
                            <div className={styles.info} >
                                <div className={styles.emailGroup} >
                                    <label className={styles.footerLabel}>{this.context.state.content.contact.ourEmailLabel}</label>
                                    <label>{this.context.state.content.contact.ourEmail}</label>
                                </div>
                                <div className={styles.phoneGroup}>
                                    <label className={styles.footerLabel}>{this.context.state.content.contact.ourPhoneLabel}</label>
                                    <label>{this.context.state.content.contact.ourPhone}</label>
                                </div>
                                {/* <div className={styles.infoGroup}>
                                    <label className={styles.footerLabel}>{this.context.state.content.contact.ourAddressLabel}</label>
                                    <label>{this.context.state.content.contact.ourAddress}</label>
                                </div> */}
                                {/* <div className={styles.infoGroup}>
                                    <label className={styles.footerLabel}>{this.context.state.content.contact.ourOpenHoursLabel}</label>
                                    <label>{this.context.state.content.contact.ourOpenHours}</label>
                                </div> */}
                            </div>
                    </React.Fragment>

                </div>
            </section>
        );

    }
}

export default ContactAlt
ContactAlt.contextType = AppContext;
