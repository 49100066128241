import React from 'react';
import content from '../../Content'

export const AppContext = React.createContext();

class AppContextProvider extends React.Component {
    state = {
        quickMenuVisible: false,
        content: content["en"]
    }

    componentDidMount() {
        const detectBrowserLanguage = require('detect-browser-language')
        var lang = detectBrowserLanguage()
        var balkans = ["hr"]


        if (balkans.includes(lang)){
            this.setState({content:content["hr"]})
        }

        
      }

    render() {
        return (
            <AppContext.Provider value={{
                state: this.state,
                showMenu: () => this.setState({
                    quickMenuVisible: true
                }),
                hideMenu: () => this.setState({
                    quickMenuVisible: false
                }),
                setLanguage: (lang) => this.setState({
                    content: content[lang]
                })
            }}>

                {this.props.children}
            </AppContext.Provider>
        )
    }
}

export default AppContextProvider;
