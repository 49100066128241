import React from "react";
// import VisibilitySensor from 'react-visibility-sensor';
import classNames from 'classnames';
import './Expander.css';

class Expander extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      enabled: false,
      expanded: false,
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.enabled !== this.state.enabled) {

    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.enabled !== prevState.enabled) {
       //console.log(nextProps)
      return { enabled: nextProps.enabled };
    }
    else return null;
  }


  handleExpand() {
    this.setState({ expanded: !this.state.expanded });	
    if (this.props.expandHandler)
    	this.props.expandHandler(!this.state.expanded);
    // console.log(this.props.expandHandler);
}



  render() {
    var btnClass = classNames({
    	'showMoreButton': true,
	'showMoreButton-expanded': this.state.expanded || !this.state.enabled,
    })

    var panelClass = classNames({
    	'panel': true,
	'panel-expanded': this.state.expanded || !this.state.enabled,
    })



    return (
      <div>
          <p className={panelClass}>{this.props.children}</p>
          <button className={btnClass} onMouseDown={this.handleExpand.bind(this)}>{this.props.title}</button>
      </div>
    );
  }
}

export default Expander;
