import React from "react";
import Expander from "../Expander/Expander";
import { AppContext } from '../AppContextProvider/AppContextProvider'
import './Approach.css';


export class Approach extends React.Component {
  render() {
    return (
      <section className="section-approach" id="approach">
        <div className="section-content-wrapper">
          <div className="section-content">
            <div className="artifact"></div>
            <div className="hero-image">
            </div>
            <h3 className="section-nav flippedY">{this.context.state.content.approach.nav}</h3>
            <h2 className="heading">{this.context.state.content.approach.heading}</h2>
            <article className="text-group">
              <p className="copy-text" >{this.context.state.content.approach.paragaph1}</p>
              <Expander enabled={window.innerHeight < 700} className="copy-text" title={this.context.state.content.misc.readMore}>
                {this.context.state.content.approach.paragaph2}
              </Expander>
            </article>

            {/* <a  className="wines-button" href="/wines">
              <button >{this.context.state.content.approach.button1}</button>
            </a>
            <form className="acquire-button" action="#wines">
              <button >{this.context.state.content.approach.button2}</button>
            </form> */}
          </div>
        </div>
      </section>
    );

  }
}

export default Approach
Approach.contextType = AppContext;
