import React, { Component } from "react";
import { AppContext } from '../AppContextProvider/AppContextProvider'
import './ContactInfo.css';


export default class ContactInfo extends Component {

    render() {
        return (
            <React.Fragment>
                {/* <div id="artifact"></div> */}
                <div id="info">

                    <div className="infoGroup" id="emailGroup">
                        <label className="footerLabel">{this.context.state.content.contact.ourEmailLabel}</label>
                        <label>{this.context.state.content.contact.ourEmail}</label>
                    </div>
                    <div className="infoGroup" id="phoneGroup">
                        <label className="footerLabel">{this.context.state.content.contact.ourPhoneLabel}</label>
                        <label>{this.context.state.content.contact.ourPhone}</label>
                    </div>
                    <div className="infoGroup" id="addressGroup">
                        <label className="footerLabel">{this.context.state.content.contact.ourAddressLabel}</label>
                        <label>{this.context.state.content.contact.ourAddress}</label>
                    </div>
                    <div className="infoGroup" id="openGroup">
                        <label className="footerLabel">{this.context.state.content.contact.ourOpenHoursLabel}</label>
                        <label>{this.context.state.content.contact.ourOpenHours}</label>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}
ContactInfo.contextType = AppContext;
