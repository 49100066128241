import React from "react";
import Expander from "../Expander/Expander";
import { AppContext } from '../AppContextProvider/AppContextProvider';
import classNames from 'classnames';

import './Story.css';

import AOS from 'aos';



export class Story extends React.Component {
  constructor(props) {
    super();

    this.state = {
      expanded: false,
    }
  }

  componentDidMount() {
    // or simply just AOS.init();
    AOS.init({
      // initialise with other settings
      duration: 2000
    });
  }

  handleExpand(expanded) {
    // this.setState({expanded: true});
  }

  render() {
    var contentWrapperClass = classNames({
      'section-content-wrapper': !this.state.expanded,
      'section-content-wrapper-expanded': this.state.expanded
    });

    return (

      <section className="section-story" id="story">
        <div className={contentWrapperClass}>
          <div className="section-content">
            <div className="artifact"></div>
            <h3 className="section-nav flippedY">{this.context.state.content.story.nav}</h3>
            <h2 className="heading">{this.context.state.content.story.heading}</h2>
            <article className="text-group">
              <p className="copy-text" >{this.context.state.content.story.paragaph1}</p>
              <Expander enabled={window.innerHeight < 700} expandHandler={this.handleExpand.bind(this)} className="copy-text" title={this.context.state.content.misc.readMore}>
                {this.context.state.content.story.paragaph2}
              </Expander>
            </article>
            <div data-aos="fade-zoom-in" className="hero-image">
            </div>
            {/* <form className="wines-button" action="#wines">
              <button >{this.context.state.content.story.button1}</button>
            </form> */}

          </div>
        </div>
      </section>
    );

  }
}

export default Story
Story.contextType = AppContext;
