import React from "react";
import Measure from 'react-measure'
import Expander from "../Expander/Expander";
import { AppContext } from '../AppContextProvider/AppContextProvider';
import classNames from 'classnames';
import './Wines.css';


export class Wines extends React.Component {
  constructor(props) {
    super();

    this.state = {
      expanded: false,
      selectedWine: 0,
      dimensions: {
        width: -1,
        height: -1,
      },
    }
  }

  toggleWines() {
    this.setState({
      selectedWine: (this.state.selectedWine === 1) ? 0 : 1,
    });
  }

  render() {
    var winesClass = classNames({
      'section-wines': true,
      'dark': true,
      'teran': this.state.selectedWine === 1,
      'malva': this.state.selectedWine === 0,
    });

    var textGroupMalvaClass = classNames({
      'text-group-malva': true,
      'blured': this.state.selectedWine === 0,
    });

    var textGroupTeranClass = classNames({
      'text-group-teran': true,
      'blured': this.state.selectedWine === 1,
    });

    var headingMalvaClass = classNames({
      'section-nav': true,
      'malva-heading': true,
      'blured': this.state.selectedWine === 0,
    });

    var headingTeranClass = classNames({
      'flippedY': true,
      'section-nav': true,
      'teran-heading': true,
      'blured': this.state.selectedWine === 1,
    });

    return (
      <section className={winesClass} id="wines" >
        <div className="section-content-wrapper">
          <div className="section-content" onClick={this.toggleWines.bind(this)} >
            {/* <div className={backgroundImageClass} onClick={this.toggleWines.bind(this)} ></div> */}
            < h1 className="heading" >{this.context.state.content.wines.nav}</h1>
            <div className={textGroupMalvaClass} >
              <p className="malva-description">{this.context.state.content.wines.malvasiaText}</p>
              {/* <p className="malva-specs">{this.context.state.content.wines.malvasiaSpecs}</p> */}
              {this.context.state.content.wines.malvasiaSpecs.map(spec => (
                <div className="malva-specs" >{spec}</div>
              ))}
            </div>
            <h3 className={headingMalvaClass}>{this.context.state.content.wines.malvasiaHeading}</h3>
            <div className="hero2"></div>
            <div className={textGroupTeranClass}>
              <p className="teran-description">{this.context.state.content.wines.teranText}</p>
              {/* <p className="teran-specs">{this.context.state.content.wines.teranSpecs}</p> */}
              {this.context.state.content.wines.malvasiaSpecs.map(spec => (
                <div className="teran-specs" >{spec}</div>
              ))}
            </div>
            <h3 className={headingTeranClass}>{this.context.state.content.wines.teranHeading}</h3>
          </div>
        </div>
      </section >


    )
  };
}

export default Wines
Wines.contextType = AppContext;