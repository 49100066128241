const Content = {

    "en": {
        "language": "English",
        "misc": {
            "quickMenu": "Quick Menu",
            "readMore": "Read More"
        },
        "homepage": {
            "nav": "Home",
            "heading": "Nature does not hurry, yet everything is accomplished.",
            "subheading": "Throughout the year, we patiently nurture our vines deeply entrenched in the rich Istrian soil. In return, they reward us with fruit of the most complex, luscious flavours which are gently captured and beautifully expressed in each bottle of our wine."
        },
        "story": {
            "nav": "Our Story",
            "heading": "Authenticity. Focus. Family.",
            "paragaph1": "Back in 2003, Panjoka’s chief winemaker Edo Babić decided to leave his 9-5 job to pursue winemaking full-time. Instead of selling the grapes in bulk as his family had done for decades, he took a leap of faith and started making the wine himself. ",
            "paragaph2": "Since then, viticulture and winemaking have become a source of relentless passion, joy and inspiration for Edo; a profession turned into a lifelong calling. His goal is to produce the best wine he possibly can, unreservedly. Year after year, in a small winery in the heart of Rovinj and with the help of his family, he succeeds, masterfully crafting some of the most exquisite, award-winning and authentic wines in Istria.",
            "button1": "Wines"
        },
        "approach": {
            "nav": "Our Approach",
            "heading": "A distilled approach to excellence.",
            "paragaph1": "As a small family-operated winery, we at Panjoka concentrate on what we know and love best. This is why we pour all our efforts into only the famous indigenous varietal of Istrian malvasia, and a red blend with prevailing notes of teran and refošk. With minimalist intervention during the winemaking process, we ensure that the vibrancy of flavours and aromas are retained and shine through the wine.",
            "paragaph2": "Sustainably grown and produced, our wines are a nod to local tradition and an ode to the beauty of living under the Istrian sun. Fruit-forward, harmonious and elegant, they reflect the pure character of this unique corner of the world.",
            "button1": "Wines",
            "button2": "Acquire",
        },
        "place": {
            "nav": "Our Place",
            "heading": "The terroir of the Mediterranean",
            "paragaph1": "A small fishing port on Croatia’s north coast, the town of Rovinj might be as close to heaven as you will ever get. With the Adriatic gently lapping its shores, and the scent of pine trees and sea salt infusing the air, it is here that the Mediterranean dream truly comes to life. ",
            "paragaph2": "The Panjoka family vineyards sit over five hectares of land on the northern outskirts of Rovinj, where the temperate Mediterranean climate graces the grapes with plentiful sunshine, and the red soil quells the thirsty vines even during the most scorching summer days. The result is fruit of remarkable quality, with vibrant flavours bursting in every berry. Through a delicate metamorphosis, they are being crafted into the type of wine that is not unlike poetry in a glass."
        },

        "wines": {
            "nav": "Wines",
            "malvasiaHeading": "Malvasia",
            "malvasiaText": "Lorem ipsum dolor sit amet nihil nunquam dormiourum. Quiorem ipsum dolor sit amet nihil nunquam dormiourum. Lorem ipsum dolor sit amet nihil nunquam dormiourum.",
            "malvasiaSpecs": [
                "GRAPES: 100% MALVASIA",
                "NOTES: Oakey",
                "ALCOHOL: 13.1%",
                "ACIDITY: 6.6g/L",
                "pH: 3.13",
                "RESIDUAL SUGAR: 1.9g/L"
            ],
            "teranHeading": "Red Blend",
            "teranText": "Lorem ipsum dolor sit amet nihil nunquam dormiourum. Quiorem ipsum dolor sit amet nihil nunquam dormiourum. Lorem ipsum dolor sit amet nihil nunquam dormiourum ipsum dolor sit amet nihil nunquam dormiourum ipsum dolor sit amet nihil nunquam dormiourum. Quiorem ipsum sit amet nihil nunquam dormiourum.",
            "teranSpecs": [
                "GRAPES: 100% TERAN",
                "NOTES: Oakey",
                "ALCOHOL: 13.1%",
                "ACIDITY: 6.6g/L",
                "pH: 3.13",
                "RESIDUAL SUGAR: 1.9g/L"
            ],
        },

        "acquire": {
            "nav": "Acquire",
            "heading": "How to buy",
            "subheading": "Lorem ipsum",
        },

        "contact": {
            "nav": "Contact",
            "heading": "How to buy",
            "firstName": "First Name",
            "lastName": "Last Name",
            "email": "Email",
            "message": "Your Message",
            "sendButton": "Send",
            "ourEmailLabel": "Email",
            "ourPhoneLabel": "Phone",
            "ourAddressLabel": "Address",
            "ourOpenHoursLabel": "Opened",
            "ourEmail": "panjokawines@gmail.com",
            "ourPhone": "+385 98 162 6334",
            "ourAddress": "Omladinska 4",
            "ourOpenHours": "Mon to Sat 3PM - 5PM"
        }
    },
    "hr": {
        "language": "Hrvatski",
        "misc": {
            "quickMenu": "Brzi Izbor",
            "readMore": "Čitaj Dalje"
        },
        "homepage": {
            "nav": "Dom",
            "heading": "Priroda nikad ne žuri, pa ipak postiže sve.",
            "subheading": "Tijekom čitave godine, strpljivo njegujemo naše loze duboko usađene u bogato istarsko tlo. Zauzvrat, one nas nagrađuju grožđem kompleksnih i opojnih okusa, koje zatim uspijevamo pažljivo očuvati i izraziti u svojem punom sjaju, u svakoj boci našeg vina."
        },
        "story": {
            "nav": "Naša Priča",
            "heading": " Autentičnost. Fokus. Obitelj.",
            "paragaph1": "2003. godine, Panjokin vinar Edo Babić odlučio je napustiti svoj posao i ured zamijeniti vinogradom na puno radno vrijeme. Umjesto da grožđe prodaje na veliko za daljnju preradu, kako je njegova obitelj činila desetljećima, po prvi puta će od tog grožđa proizvoditi vlastito vino. ",
            "paragaph2": "Od tada, vinogradarstvo i vinarstvo postali su Edov izvor profesionalnog žara, radosti i inspiracije; profesija prerasla u životni poziv. Njegov je cilj stvoriti najbolje vino moguće, bez rezerve. Godinu za godinom, u maloj vinariji u srcu Rovinja i uz pomoć svoje obitelji, on u tome uspijeva, majstorski oblikujući neka od najprobranijih, najnagrađivanijih i najautentičnijih vina u Istri.",
            "button1": "Vina"
        },
        "approach": {
            "nav": "Naš Pristup",
            "heading": "Usredotočenim pristupom do izvrsnosti.",
            "paragaph1": "Kao mala obiteljska vinarija, koncentrirali smo se na ono što najbolje znamo i najviše volimo. Iz tog razloga ulažemo sav naš trud u autohtonu istarsku sortu malvaziju, te crnu kupažu kojom dominiraju teran i refošk. Zahvaljujući minimalnoj intervenciji tijekom proizvodnog procesa, razigranost okusa i aroma su očuvani i dolaze do potpunog izražaja u vinu.",
            "paragaph2": "Održivo uzgojena i proizvedena, naša su vina naklon lokalnoj tradiciji i oda ljepoti življenja pod istarskim suncem. Definiranih voćnih nota, harmonična i elegantna, ona odražavaju izvorni karakter ovog jedinstvenog kutka svijeta",
            "button1": "Vina",
            "button2": "Kako kupiti",
        },
        "place": {
            "nav": "Naše Mjesto",
            "heading": "Terroir Mediterana",
            "paragaph1": "Rovinj je mala ribarska luka na sjevernoj obali Hrvatske i mjesto u kojem ste vjerojatno najbliže raju na zemlji. Dok mu Jadransko more umiva obale, a mirisi borovine i morske soli ispunjavaju zrak, san o Mediteranu ovdje postaje java.",
            "paragaph2": "Naši obiteljski vinogradi se prostiru na pet hektara zemlje na sjevernom rubu Rovinja, gdje blaga mediteranska klima dariva naše grozdove izdašnim sunčevim sjajem, a tlo crvenica napaja lozu vodom i tijekom najsuših ljetnih dana. Rezultat je grožđe iznimne kvalitete i blistavih okusa kojima pršti svaka bobica. Naposljetku, ono se kroz sofisticiranu metamorfozu pretvara u vino koje evocira poeziju u čaši."
        },

        "wines": {
            "nav": "Vina",
            "malvasiaHeading": "Malvazija",
            "malvasiaText": "Lorem ipsum dolor sit amet nihil nunquam dormiourum. Quiorem ipsum dolor sit amet nihil nunquam dormiourum. Lorem ipsum dolor sit amet nihil nunquam dormiourum.",
            "malvasiaSpecs": [
                "GROZDJICE: 100% MALVAZIJA",
                "TOCKE: Oakey",
                "ALKOHOL: 13.1%",
                "ACIDITY: 6.6g/L",
                "pH: 3.13",
                "RESIDUAL SUGAR: 1.9g/L"
            ],
            "teranHeading": "Crvena kupaža",
            "teranText": "Lorem ipsum dolor sit amet nihil nunquam dormiourum. Quiorem ipsum dolor sit amet nihil nunquam dormiourum. Lorem ipsum dolor sit amet nihil nunquam dormiourum ipsum dolor sit amet nihil nunquam dormiourum ipsum dolor sit amet nihil nunquam dormiourum. Quiorem ipsum sit amet nihil nunquam dormiourum.",
            "teranSpecs": [
                "GRAPES: 100% TERAN",
                "NOTES: Oakey",
                "ALCOHOL: 13.1%",
                "ACIDITY: 6.6g/L",
                "pH: 3.13",
                "RESIDUAL SUGAR: 1.9g/L"
            ],
        },

        "acquire": {
            "nav": "Trgovina",
            "heading": "Kako kupiti",
            "subheading": "Lorem ipsum",

        },
        "contact": {
            "nav": "Kontakt",
            "heading": "Kako kupiti",
            "firstName": "Ime",
            "lastName": "Prezime",
            "email": "Email",
            "message": "Vaša Prouka",
            "sendButton": "Pošalji",
            "ourEmailLabel": "Email",
            "ourPhoneLabel": "Telefon",
            "ourAddressLabel": "Adresa",
            "ourOpenHoursLabel": "Otvoreno",
            "ourEmail": "panjokawines@gmail.com",
            "ourPhone": "+385 98 162 6334",
            "ourAddress": "Omladinska 4",
            "ourOpenHours": "Pon do Sub 3PM - 5PM"
        }
    }
}

export default Content;
