import React, { Component } from "react";
import { AppContext } from '../AppContextProvider/AppContextProvider'
import styles from './QuickMenu.module.css';

export default class QuickMenu extends Component {

    render() {
        return (
            <div id="quickmenu" className={(this.context.state.quickMenuVisible) ? styles.quickMenuExpanded : styles.quickMenu}>
                <div className={styles.navItemsWrapper}>
                    <h3 className="section-nav dark">{this.context.state.content.misc.quickMenu}</h3>
                    <div className={styles.navItems}>
                        <a href="/">{this.context.state.content.homepage.nav}</a>
                        <a href="/#story">{this.context.state.content.story.nav}</a>
                        <a href="/#approach">{this.context.state.content.approach.nav}</a>
                        <a href="/#place">{this.context.state.content.place.nav}</a>
                        {/* <a href="/wines">{this.context.state.content.wines.nav}</a> */}
                        {/* <a href="/acquire">{this.context.state.content.acquire.nav}</a> */}
                        <a href="/shop">{this.context.state.content.contact.nav}</a>
                    </div>
                    <div className={styles.closeButton} onMouseDown={this.context.hideMenu} />
                </div>
            </div >
        );
    }
}

QuickMenu.contextType = AppContext;
