import React from "react";
import { AppContext } from '../AppContextProvider/AppContextProvider';
import './Acquire.css';


export class Acquire extends React.Component {

    handleExpand(expanded) {
        // this.setState({expanded: true});
    }

    render() {

        return (
            <section className="section-acquire dark" id="acquire" >
                <div className="section-content-wrapper">
                    <div className="section-content">
                        {/* <div className="artifact"></div> */}
                        <h3 className="section-nav flippedY">{this.context.state.content.acquire.nav}</h3>
                        {/* <h2 className="heading">{this.context.state.content.acquire.heading}</h2> */}
                        <article className="text-group">
                            <p className="copy-text" >{this.context.state.content.story.paragaph2}</p>
                        </article>
                        <div className="hero-image"></div>


                    </div>
                </div>
            </section>


        )
    };
}

export default Acquire
Acquire.contextType = AppContext;