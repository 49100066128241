import React, { Component } from "react";
import { AppContext } from '../AppContextProvider/AppContextProvider'

import logo from './logo.svg';
import styles from './Navbar.module.css';

export default class Navbar extends Component {
  render() {
    return (
      <nav id="navbar" className={styles.nav}>
        <div className={styles.navContent}>
          <a href="/"> <img src={logo} className={styles.navLogo} alt="PANJOKA"></img></a>
          <div className={styles.navItems}>
            <div className={styles.quickMenuLabel} onMouseDown={this.context.showMenu}>{this.context.state.content.misc.quickMenu}</div>
            <a href="/#story">{this.context.state.content.story.nav}</a >
            <a href="/#approach">{this.context.state.content.approach.nav}</a >
            <a href="/#place">{this.context.state.content.place.nav}</a >
            {/* <a href="/wines"><li>{this.context.state.content.wines.nav}</li></a > */}
            {/* <a href="/shop">{this.context.state.content.acquire.nav}</a > */}
            <a href="/shop">{this.context.state.content.contact.nav}</a >
            <div className={styles.navButton} onMouseDown={this.context.showMenu} />
          </div>
        </div>
      </nav>
    );
  }
}

Navbar.contextType = AppContext;
